.footer-parent-cont{
    background: linear-gradient(rgba(8, 77, 108, .80), rgba(102, 173, 108, .85)), url("../assets/jangle.jpg") center center no-repeat;
    background-size: cover;
}
.footer-logo-cont{
    text-align: center;
    padding: 20px;
}
.footer-logo-cont img{
    width: 100px;
}
.enquiry-form-cont{
    display: flex;
    min-height: 400px;
    align-items: center;
    justify-content: center;
    padding:10px;
}
.drop-enquiry{
    margin-bottom: 20px;
   
}
.enquiry-leftside{
    width: 50%;
   
}
.enquiry-leftside img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.enquiry-rightside{
    width: 50%;
    padding: 20px;
  
}
.enquiry-rightside form input{
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 50px;
    margin: 15px 0;
}
.enquiry-submit-btn-cont{
    text-align: center;
}
.enquiry-submit-btn{
    margin: 10px 0;
    padding: 10px;
    width: 100%;
}
.enquiry-rightside h1, .enquiry-rightside h2{
    text-align: center;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
}
.enquiry-rightside h2{
    margin-top: 10px;
}
/* Enquiry btn hover effect */
.enquiry-submit-btn {
    color: #FFF;
  
    font-size: larger;
    font-weight: 700;
    position: relative;
    background-color: var(--colorPrimary);
  }
  .enquiry-submit-btn:hover{
    background-color: auto !important;
  }

  .enquiry-submit-btn::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    border-top-width: 1px;
    border-bottom-width: 1px;
   
  }
  .enquiry-submit-btn:hover span {
    letter-spacing: 1px;
    color: var(--colorSecondary);
  }
  .enquiry-submit-btn:hover::before {
    opacity: 1; 
    
  }
  .enquiry-submit-btn::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
   
    background-color: rgba(255,255,255,0.1);
  }
  .enquiry-submit-btn:hover::after {
    opacity: 0; 
   
  }

@media screen and (max-width:900px){
    .enquiry-form-cont{
        flex-direction: column;
    }
    .enquiry-leftside, .enquiry-rightside{
        width: 100%;
    }
}