.popupContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 20000;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: opacity 0.3s ease;
  }
  
  .popupContainer.exit {
    opacity: 0;
  }
  
  .popup {
    position: relative;
    background-color: #fff;
    border-radius: 5px;
    width: 370px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    opacity: 0;
    transform: scale(0.8);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .popupEffect {
    opacity: 1;
    transform: scale(1);
  }
  
  .popupContainer.exit {
    animation: exitAnimation 0.3s ease;
  }
  
  @keyframes exitAnimation {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  .popup-img-cont {
    text-align: center;
    background: #084d6c;
    padding: 15px;
  }
  
  .popup-img-cont img {
    width: 120px;
  }
  
  .popup-inp-cont {
    padding: 30px 20px;
  }
  
  .popup-inp-cont h2 {
    font-size: x-large;
  }
  
  .view-cost {
    text-align: center;
  }
  
  .popup h2 {
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
    color: rgb(179, 55, 104);
  }
  
  .popup input[type="text"],
  .popup input[type="email"],
  .popup input[type="number"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .popup-submit-btn-cont {
    text-align: center;
  }
  
  .submit-btn {
    width: 100%;
    background: linear-gradient(to right,  #78b650, #084d6c);
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .close-btn {
    position: absolute;
    right: 5px;
    top: 4px;
    background: var(--colorPrimary);
    color: white;
    border: none;
    padding: 2px 6px;
  }
  
  .popup button[type="submit"] {
    margin-right: 10px;
  }
  
  .popup button[type="submit"]:hover,
  .popup button[type="button"]:hover {
    background-color: var(--colorPrimary);
  }
  