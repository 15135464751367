/* MobileButtons.css */
.mobile-buttons {
    display: none; /* Hide the buttons by default */
    justify-content: space-around;
    background-color: #333; /* Set your desired background color */
    padding: 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 999; /* Adjust the z-index as needed */
  }
  
  .enquir-button{
    background: linear-gradient(to right,  #78b650, #084d6c);/* Set your desired button background color */
    color: #fff; /* Set text color */
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
  }
  .cost-button{
    background: linear-gradient(to right,  #78b650, #084d6c);/* Set your desired button background color */
    color: #fff; /* Set text color */
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
  }
  .whatsapp-button {
    background: linear-gradient(to right,  #78b650, #084d6c); /* Set your desired button background color */
    color: #fff; /* Set text color */
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
  }
  
  .cost-button {
    background-color: #78b650; /* Set a different color for the "Cost" button */
  }
  
  .whatsapp-button {
    background-color: #25d366; /* Set a different color for the "WhatsApp" button */
  }
  
  /* Media query to show buttons only for mobile view (max-width: 768px) */
  @media (max-width: 768px) {
    .mobile-buttons {
      display: flex; /* Show the buttons for mobile view */
    }
  }
  