*{
    margin: 0;
    padding: 0;
}

.hero {
    background-image: url(../assets/heroimg.jpg); /* Replace with your image path */
    background-size: cover;
    background-position: center;
    height: 85vh;
    /* Other styles for your hero section */
  }

 
  
.form-container {
    position: absolute;
    top: 50%; /* Position the form container vertically in the middle */
    right: 3%; /* Position the form container horizontally in the middle */
    transform: translate(-50%, -50%); /* Center the form container */
    background-color: rgba(255, 255, 255, 0.8); /* Add a semi-transparent white background */
    padding: 20px;
    border-radius: 10px;
    width: 270px;
    height: 342px;
    text-align: center;
    margin-top: 20px; 
  }
  
  .form h2 {
    text-align: center;
    margin-bottom: 7px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form label {
    display: block;
    font-weight: bold;
  }
  
  .form input[type="text"],
  .form input[type="email"],
  .form input[type="number"],
  .form input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .form button {
    background: linear-gradient(to right,  #78b650, #084d6c);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    font-weight: bold;
  }
  
 
  .heading,
  .headtwo {
    position: absolute;
  
    
  }
  
  .heading {
    color: #fff; /* Text color */
    font-size: 40px; /* Adjust font size as needed */
    top: 50%; /* Position the h1 tag vertically */
    
    background-color: rgba(179, 55, 104,  0.7); /* Semi-transparent blue background under h1 */
    padding: 40px; /* Adjust padding to control the size of the background */
    font-weight: bold;
    
  }
  
  .headtwo {
    color: #fff; /* Text color */
    font-size: 50px; /* Adjust font size as needed */
    top: 40%; /* Position the h2 tag vertically */
    right: 60%; /* Position the h2 tag horizontally */
    transform: translateX(30%); /* Center the h2 tag horizontally */
    background-color: rgba(179, 55, 104,); /* Semi-transparent blue background under h2 */
    padding: 10px; /* Adjust padding to control the size of the background */
  }

  .boldb{
    font-weight: bold;
    color: rgb(179, 55, 104);
  }
  .sub{
    transition: all ease 0.5s;
  }
  .move{
    margin-bottom: 18px;
  }

  @media (max-width: 768px) {
    .heading {
      display: none;
    }
    .hero {
      text-align: center;
    }

    
  
    .form-container {
      top: 20%; 
      left: 50%;
      transform: translateX(-50%);
      max-width: 80%; /* Adjust the maximum width as needed */
    }
    
    /* Center the form within the form container */
    .form {
      text-align: left; /* Align form elements to the left */
    }
  
  }

 
