.thankyou-cont{
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    /* background-size: 100% 100%; */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.thankyou-cont h1{
    color: white;
    text-align: center;
}
.thankyou-cont h2{
    color: white;
    text-decoration: underline;
    cursor: pointer;
}