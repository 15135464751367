.floorplan-cont{
   
    padding: 20px;
    min-height: 400px;
}
.floorplanimg-cont{
    display: flex;
    justify-content: center;
    column-gap: 10px;
    padding: 10px;
    flex-wrap: wrap;
}
.floorplan-header{
    text-align: center;
    padding: 20px;
}
.floorplan-cont span{
    color:#937445;
}
.floorplan-img-btn-cont{
    width: 338px;
    position: relative;
}
.floorplanimg-cont{
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 20px 0;
    flex-wrap: wrap;
}
.floorplanimg-cont img{
    width: 100%;
    height: 100%;
    display: block;
    filter: blur(2px);
}
.floorplan-img-btn-cont button{
    position: absolute;
    width: 100px;
    padding: 11px;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: auto;
    background: linear-gradient(to right,  #78b650, #084d6c);
    color: white;
    border: none;
    border-radius: 4px;
    font-weight: bold;
}
.floorplan-img-btn-cont button:hover{
    cursor: pointer;
}
.floorplanimg-cont img:hover{
    box-shadow: 3px 3px 6px 2px grey;
}
.head{
    text-align: center;
     margin-top: 30px;
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: bold;
    color: rgb(179, 55, 104);
    text-decoration: underline; /* Add underline */
    text-decoration-color: rgba(8, 77, 108);
   }
   