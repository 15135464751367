
/* Navbar.css */
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Poppins,sans-serif;
}


.navbar {
background-color:#fff;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
}

.logo {
  width: 100px; /* Adjust the logo size as needed */
  height: auto;
}

.navbar-center {
  flex-grow: 1;
  display: flex;
  justify-content: end;
}

.navbar-list {
  list-style-type: none;
  display: flex;
  padding: 0;
 
}

.navbar-item {
  margin: 8px 20px;
}

.navbar-link {
  text-decoration: none;
  color: rgba(8, 77, 108);
  font-weight: bold;
}

.navbar-link:hover {
  
  color: rgba(179, 55, 104);
}

.enquiry-button {
  background: linear-gradient(to right, #78b650, #084d6c); /* Change button color as desired */
  color:#fff ;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;   
  display: flex;
  align-items: center;
  transition: all ease 0.5s;
}

.enquiry-button svg {
  margin-right: 5px; /* Add some space between the icon and text */
}

/* Add these styles to your existing CSS file */

/* Mobile menu styles */
.navbar-list.mobile-menu-open {
display: block;
position: absolute;
top: 70px; /* Adjust the top position as needed */
left: 0;
width: 100%;
background-color: #fff; /* Background color for the mobile menu */
z-index: 1;
box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}

/* Styles for individual mobile menu items */
.navbar-list.mobile-menu-open .navbar-item {
display: block;
padding: 7px 0px;
}

/* Styles for the mobile menu button */
.mobile-menu-button {
display: none; /* Initially hidden on larger screens */
background: none;
border: none;
cursor: pointer;
font-size: 24px;
color: #333;
}

.active-link {
  color: pink; /* Set the color to pink or your desired color */
}


@media (max-width: 768px) {
/* Show the hamburger menu button and hide the desktop menu */
.mobile-menu-button {
  display: block;
}
.navbar-list {
  display: none;
}
.navbar-list.mobile-menu-open .navbar-item {
  margin-bottom: 2px; /* Add margin-bottom for mobile view only */
}
.mbz{
  margin-bottom: 4px;
  margin-left: 6px;
}
}





