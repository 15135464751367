
.yes-icon{
    height: 25px; /* Set the desired height */
    margin-right: 10px; /* Adjust the margin to control spacing between icon and text */
  }
  
  /* Style for the icon containers */
  .icon-container {
    display: flex; /* Use flexbox to align items horizontally */
    align-items: flex-start; /* Align items to the top */
    margin-bottom: 10px; /* Add spacing between each paragraph */
  }

.head{
    text-align: center;
     margin-top: 30px;
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: bold;
    color: rgb(179, 55, 104);
    text-decoration: underline; /* Add underline */
    text-decoration-color: rgba(8, 77, 108);
   }
.container{
    width: 90%;
    margin: 0 auto;
    padding: 10px 20px;
}
.about{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 50px;
}

.about-image{
    flex: 1;
    margin-right: 40px;
    overflow: hidden;
    /* transition: transform 0.3s ease;  */
   
}
.about-image img{
    max-width: 100%;
    height: auto;
    display: block;
    /* transition: 0.5% ease; */
    border-radius: 10px;
    border: 10px solid rgba(8, 77, 108); /* You can adjust the border width and color as needed */
    border-radius: 8px; 
    height: 25rem;
    width: 45rem;
}
.about-content{
    flex: 1;
    
}

.about-content p{
    font-size: 18px;
    line-height: 1.5;
    color: #666;
    margin-bottom: 20px;
}

@media screen and (max-width:768px) {
    .container{
        padding: 0px;
    }
    .about{
        padding: 20px;
        flex-direction: column;
    }
    .about-image{
        margin-right: 0px;
        margin-bottom: 20px;
    }
    .about-content p{
      padding: 0px;
      font-size:16px ;
    }
}





