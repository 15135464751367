@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');
.disclaimer-cont{
    background: black;
    color: white;
    padding: 10px 110px;
    text-align: center;
}
.disclaimer-head{
    text-align: center;
    padding: 10px;
}
.disclaimer-head span, .disclaimer span{
    color:rgb(3, 168, 223);
    text-decoration: underline;
}
.disclaimer{
    font-size: 11px;
    line-height: 1.5;
    font-weight: 700;
}
.disclaimer a {
  color: blue;
 
}
.disclaimer-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
  }
  
  .disclaimer {
    margin-right: 20px; /* Adjust the margin as needed to create space between the text and the image */
  }
  
  .qrimg {
    height: 58px;
  }
  


@media screen and (max-width:800px) {
    .disclaimer-cont{
        padding: 10px !important;
      }    
}