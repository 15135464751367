.best-pricing-table-cont{
    margin: 29px;
    background-image: url("");
    background-repeat: no-repeat;
    background-size: cover;
}
.pricing-header-cont{
   justify-content: center;
}
.headside-border{
    border-right: 5px solid #B07936;
}
.table {
  border-collapse: collapse;
  width: 100%;
}
td{
    vertical-align: middle;
}
tr{
    border-bottom: 3px solid rgba(8, 77, 108);
}
thead tr{
    border-bottom: none;
}
.table th,
.table td {
  border: 1px solid rgba(179,55, 108);
  padding: 8px;
  text-align: center;
  text-align: -webkit-center;
}

.table p {
  width: 40%;
  margin: 0;
  padding: 5px;
 
}
thead{
    background: rgba(8, 77, 108);
    color: white;
}
tbody{
    background: rgb(102, 173, 70 ,0.5);
}
.price-btn{
    background: linear-gradient(to right, #b33768, #084d6c);
    color: white;
    padding: 9px;
    border-radius: 7px;
    font-weight: bold;
}
.table-btn{
    vertical-align: middle;
}

.head{
    text-align: center;
     margin-top: 30px;
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: bold;
    color: rgb(179, 55, 104);
    text-decoration: underline; /* Add underline */
    text-decoration-color: rgba(8, 77, 108);
   }
 
   
@media screen and (max-width:800px) {
    .best-pricing-table-cont{
        flex-direction: column;
        
    }
    .table th,
.table td {
  border: 1px solid rgba(179,55, 108);
  padding: 0px;
  font-size: 12px;
  text-align: center;
  text-align: -webkit-center;
}

}